import { CellContext, ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'
import { cellEmptyStaticValue } from 'components/organisms/custom-table/helpers'

import { ProtocolActionProps } from 'app/core/types/hormonal'

const protocolActionColumns = (
  setSelectedAction?: (action: ProtocolActionProps) => void,
  hasVinculatedIatfSchedule?: boolean,
): ColumnDef<ProtocolActionProps>[] => [
  {
    header: 'Dia',
    accessorKey: 'day',
    cell: (info): JSX.Element => <span>D{info.row.original.day}</span>,
  },
  {
    header: 'Pedido',
    accessorKey: 'action',
  },
  {
    header: 'Quantidade',
    accessorKey: 'dose',
    cell: (info): JSX.Element => {
      const { dose } = info.row.original
      return <span>{dose ? `${dose} ml` : '-'}</span>
    },
  },
  {
    header: 'Fabricante',
    accessorKey: 'provider',
    cell: (info): JSX.Element =>
      cellEmptyStaticValue(info.row.original.provider),
  },
  ...(setSelectedAction
    ? [
        {
          header: '',
          accessorKey: 'id',
          cell: (
            info: CellContext<ProtocolActionProps, unknown>
          ): JSX.Element => (
            <Icon
              disabled={hasVinculatedIatfSchedule}
              name={IconNames.close}
              onClick={(): void => {
                setSelectedAction(info.row.original)
              }}
            />
          ),
        },
      ]
    : []),
]

export { protocolActionColumns }
