import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
} from 'components/molecules'
import { BoxedTable, ISelectButtonItem, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { ANIMAL_CATEGORY_FILTER, ANIMAL_SEX } from 'app/core/types/filters'
import { ShutdownCreateProps } from 'app/core/types/shutdown'
import { Messages } from 'config/messages'

type ShutdownCreateTemplateProps = {
  navigateTo: () => void
  handleToggleDrawer: () => void
  animals: ShutdownCreateProps[]
  table: Table<ShutdownCreateProps>
  isDrawerOpen: boolean
  onSubmit: IFormProps['onSubmit']
  shutdownDate: string
  setShutdownDate: Dispatch<SetStateAction<string>>
  headerSelectButtonItems: ISelectButtonItem[]
  isLoading: boolean
}

const ShutdownCreateTemplate: React.FC<ShutdownCreateTemplateProps> = ({
  navigateTo,
  handleToggleDrawer,
  animals,
  table,
  isDrawerOpen,
  onSubmit,
  shutdownDate,
  setShutdownDate,
  headerSelectButtonItems,
  isLoading,
}) => {
  const { Form, reset } = useForm({ onSubmit })

  const resetFilters = (): void => {
    reset()
  }

  const weightOrShutdownReasonIndex = 6

  return (
    <BoxedLayout
      title="Baixas de animais"
      headerLargeButtonAction={navigateTo}
      headerLargeButtonLabel="Revisar"
      headerLargeButtonIconPosition={ButtonIconPosition.right}
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
      headerSelectButtonLabel="Planilha"
      headerSelectButtonItems={headerSelectButtonItems}
      headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
      headerSelectButtonIconPosition={ButtonIconPosition.right}
      date={shutdownDate}
      setDate={setShutdownDate}
      setDateMaxToday
      isChildrenSticky
    >
      <BoxedTable
        data={animals}
        table={table}
        title="Animais"
        quantityLabel="Selecionado"
        mainButtonLabel="Incluir"
        mainButtonIcon={IconNames['add-circle']}
        mainButtonAction={handleToggleDrawer}
        disableLastCellNavigation
        disableCellsNavigationByIndex={[weightOrShutdownReasonIndex]}
        noDataMessage={Messages.ANIMAL_NOT_ADDED}
        isLoading={isLoading}
        isTableHeaderSticky
      />

      <CustomDrawer
        title="Adicionar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.Select
                label="Categoria"
                name="categories"
                options={ANIMAL_CATEGORY_FILTER}
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período de Nascimento"
                name="init_date"
                htmlType="date"
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.RadioButton
                chipButtonMode
                title="Sexo do Animal"
                name="sex"
                options={ANIMAL_SEX}
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </BoxedLayout>
  )
}

export { ShutdownCreateTemplate }
