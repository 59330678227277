/* eslint-disable @typescript-eslint/naming-convention */
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import {
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  InputText,
  Select,
  SelectItemProps,
} from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'
import {
  cellEmptyStaticValue,
  handleDeleteRow,
} from 'components/organisms/custom-table/helpers'
import { CHIP_CATEGORY } from 'components/templates/animal-layout/consts'

import { useShutdown } from 'app/core/hooks/shutdown'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalRequestData, AnimalSexLabel } from 'app/core/types/animal'
import {
  ShutdownCreateProps,
  ShutdownDescriptionStorageProps,
  ShutdownType,
  ShutdownTypeKeys,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  downloadFile,
  generateXlsxTemplate,
  removeEmptyFilters,
  shutdownEmptyFields,
} from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

import styles from './styles.module.scss'
import { ShutdownCreateTemplate } from './template'

const defaultColumn: Partial<ColumnDef<ShutdownCreateProps>> = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const shutdownDescriptions = JSON.parse(
      localStorage.getItem(StorageKeys.shutdown_descriptions) as string
    ) as ShutdownDescriptionStorageProps

    const initialValue = getValue()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState(initialValue)

    const onBlur = (): void => {
      table.options.meta?.updateData(index, id, value)
    }

    const onSelect = (selectedValue: string | boolean): void => {
      setValue(selectedValue)
      table.options.meta?.updateData(index, id, selectedValue)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    if (id === ('weight' as keyof ShutdownCreateProps)) {
      return (
        <span className={styles.input}>
          <InputText
            name={id}
            onChange={(e): void => setValue(e.target.value)}
            defaultValue={value as string}
            onBlur={onBlur}
            htmlType="number"
            placeholder="Inserir peso"
            width={120}
          />{' '}
          kg
        </span>
      )
    }

    if (id === ('shutdown_description' as keyof ShutdownCreateProps)) {
      return (
        <Select
          name={id}
          centerText
          options={shutdownDescriptions.options}
          onChange={(value): void => {
            onSelect((value as SelectItemProps).value)
          }}
          defaultValue={shutdownDescriptions.options?.find(
            e => e.value === (value || shutdownDescriptions.selectedValue)
          )}
        />
      )
    }

    return <span>{value as string}</span>
  },
}

const ShutdownCreate: React.FC = () => {
  const history = useHistory()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const {
    animals,
    setAnimals,
    importShutdown,
    savedShutdownType,
    shutdownDate,
    setShutdownDate,
    isLoading,
  } = useShutdown()

  if (!savedShutdownType) {
    history.push(NavigateList.animalList)
  }

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleGoToReview = (): void => {
    const requiredFields = ['weight']
    const animalErrors = shutdownEmptyFields(animals, requiredFields)

    if (
      animalErrors.length &&
      savedShutdownType.shutdown_type === ShutdownTypeKeys.sale
    ) {
      animalErrors?.forEach(invalidAnimal => {
        setAnimals(prevRows => {
          return prevRows.map(row => {
            if (row.id === invalidAnimal) {
              return {
                ...row,
                error_message: Messages.REQUIRED_FIELDS,
              }
            }
            return row
          })
        })
      })

      addToast({ message: Messages.REQUIRED_FIELDS })
      return
    }

    if (!animals.length) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    setAnimals(prevRows => {
      return prevRows.map(row => {
        return {
          ...row,
          error_message: undefined,
        }
      })
    })

    history.push(NavigateList.shutdownReview)
  }

  const handleAddAnimals = async (data: Record<string, unknown>): Promise<void> => {
    const filterParams = {
      is_active: 'true',
      ...data
    }
    await http
      .get(`/animals/`, { params: removeEmptyFilters(filterParams) })
      .then(res => {
        const response = res.data.items as ShutdownCreateProps[]
        const currentAnimalsIds = animals.map(animal => animal.id)
        const findAnimals = response.filter(
          e => !currentAnimalsIds.includes(e.id)
        )

        if (!findAnimals.length) {
          addToast({ message: Messages.NOT_FOUND_FILTER_ANIMALS })
          return
        }

        const newAnimals = findAnimals.map(animal =>
          Object.assign(animal, { weight: undefined })
        ) as ShutdownCreateProps[]

        setAnimals(prevAnimals => prevAnimals.concat(newAnimals))
        handleToggleDrawer()
      })
      .catch(e => {
        addToast({ message: e.message })
        handleToggleDrawer()
      })
  }

  const columnHelper = createColumnHelper<ShutdownCreateProps>()

  const columns = [
    columnHelper.accessor('electronic_eartag', {
      header: 'Nº de Brinco Eletrônico',
      cell: (info): JSX.Element =>
        cellEmptyStaticValue(info.row.original.electronic_eartag),
    }),
    columnHelper.accessor('stock_number', {
      header: 'Nº de Plantel',
      cell: (info): JSX.Element =>
        cellEmptyStaticValue(info.row.original.stock_number),
    }),
    columnHelper.accessor('birth_number', {
      header: 'Nº de Nascimento',
      cell: (info): JSX.Element =>
        cellEmptyStaticValue(info.row.original.birth_number),
    }),
    columnHelper.accessor('sex', {
      header: 'Sexo',
      cell: (info): JSX.Element => (
        <Chip
          colorVariant={
            info.row.original.sex === AnimalSexLabel.female
              ? ChipColorVariant.sexFemale
              : ChipColorVariant.sexMale
          }
        />
      ),
    }),
    columnHelper.accessor('category', {
      header: 'Categoria',
      cell: (info): JSX.Element => (
        <Chip colorVariant={CHIP_CATEGORY[info.row.original.category]} />
      ),
    }),
    ...(savedShutdownType?.shutdown_type === ShutdownTypeKeys.sale
      ? [
          columnHelper.accessor('weight', {
            header: 'Peso de Venda',
          }),
        ]
      : []),
    ...(savedShutdownType?.shutdown_type !== ShutdownTypeKeys.sale
      ? [
          columnHelper.accessor('shutdown_description', {
            header: `${
              savedShutdownType?.shutdown_type === ShutdownType.other
                ? 'Causa da Baixa'
                : 'Causa da Morte'
            }`,
          }),
        ]
      : []),
    columnHelper.accessor('id', {
      header: '',
      cell: (info): JSX.Element => (
        <Icon
          name={IconNames.close}
          onClick={(): void =>
            handleDeleteRow(animals, setAnimals, info.row.index)
          }
        />
      ),
    }),
  ]

  const table = useReactTable({
    data: animals,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setAnimals(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    importShutdown(selectedFile)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nº de Plantel',
            'Nº de Brinco eletrônico',
            'Nº de Nascimento',
            'Peso',
          ],
          ['', '', '', ''],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-baixas',
        })
      },
    },
  ]

  useEffect(() => {
    localStorage.setItem(StorageKeys.shutdown_review, JSON.stringify(animals))
    localStorage.setItem(StorageKeys.shutdown_date_last_review, shutdownDate)
  }, [animals, shutdownDate])

  return (
    <ShutdownCreateTemplate
      navigateTo={handleGoToReview}
      handleToggleDrawer={handleToggleDrawer}
      animals={animals}
      table={table}
      isDrawerOpen={isDrawerOpen}
      onSubmit={handleAddAnimals}
      shutdownDate={shutdownDate}
      setShutdownDate={setShutdownDate}
      headerSelectButtonItems={selectButtonItems}
      isLoading={isLoading}
    />
  )
}

export { ShutdownCreate }
