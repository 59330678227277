import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'
import * as yup from 'yup'
import { debounce } from 'lodash-es'

import {
  CheckboxButton,
  ICheckboxButtonOption,
  SelectItemProps,
} from 'components/atoms'
import { DrawerRow, LabeledCard, LabeledCardCol, withLoading } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  CreateMeasurementRequestData,
  ModalState,
  ModalType,
  Period,
  PrecipitationProps,
  PrecipitationResponseProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { isEndDateBeforeStartDate, onlyAlphanumericString } from 'app/core/utils'

import {
  PrecipitationCreateModal,
  PrecipitationDeleteModal,
  PrecipitationUpdateModal,
} from './modal'
import styles from './styles.module.scss'
import { Messages } from 'config/messages'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  initDate: yup.string(),
  finalDate: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { initDate } = this.parent
        return isEndDateBeforeStartDate(initDate, value)
      }
    ),
})

type PrecipitationTemplateProps = {
  handleStation: (
    isChecked: boolean,
    value: string,
    isExternalStation: boolean
  ) => void
  handlePeriod: (name: string, value: string) => void
  period: Period
  handleModal: (type: ModalType) => void
  isModalOpen: ModalState
  isLoading: boolean
  isLoadingStations: boolean
  table: Table<PrecipitationProps>
  precipitations: PrecipitationResponseProps
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  stationsCheckboxList: ICheckboxButtonOption[]
  stationsDroplist: SelectItemProps[]
  createMeasurement: (request: CreateMeasurementRequestData) => Promise<void>
  updateMeasurement: (
    measurementId: number,
    request: UpdateMeasurementRequestData
  ) => Promise<void>
  deleteMeasurementItem: (measurementId: number) => Promise<void>
  selectedItem: PrecipitationProps | undefined
}

const PrecipitationTemplate: React.FC<PrecipitationTemplateProps> = ({
  handleStation,
  handlePeriod,
  period,
  handleModal,
  isLoading,
  isLoadingStations,
  isModalOpen,
  table,
  precipitations,
  stationsCheckboxList,
  stationsDroplist,
  createMeasurement,
  updateMeasurement,
  deleteMeasurementItem,
  selectedItem,
  currentPage,
  setCurrentPage,
}) => {
  const stationsLoadingTemplate = (): JSX.Element => (
    <div className={styles.stationsLoading}>
      <Skeleton width={200} height={34} count={5} />
    </div>
  )
  const preicipitationsLenght = precipitations?.items.length ?? 0
  const totalPages = precipitations?.pages ?? 0

  function handleFormSubmit(data: Record<string, unknown>): Promise<void> {
    return new Promise<void>((resolve) => {
      const period: Period = {
        initDate: (data as { initDate: string }).initDate,
        finalDate: (data as { finalDate: string }).finalDate,
      };
      handlePeriod("initDate", period.initDate);
      handlePeriod("finalDate", period.finalDate);
      resolve();
    });
  }

  const handleDebouncePeriod = debounce(
    async (name: string, value: string): Promise<void> => {
      if (!value) return
      await handlePeriod(name, value)
    },
    1000
  )

  const { Form } = useForm({
    onSubmit: handleFormSubmit, validationSchema,
    mode: "onChange"
  })

  return (
    <BoxedLayout
      title="Precipitações"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      currentPageItems={preicipitationsLenght}
      currentPageItemsLabel={'precipitações'}
      headerLargeButtonAction={(): void => handleModal(ModalType.create)}
      headerLargeButtonLabel="Novo lançamento"
    >
      <LabeledCard title="Filtros">
        <div className={styles.precipitationFilters}>
          <LabeledCardCol>
            <label className={styles.label}>
              Selecione uma ou mais estações
            </label>
            {withLoading(
              <CheckboxButton
                chipButtonMode
                name="stations"
                options={stationsCheckboxList || []}
                onChange={(e): void =>
                  handleStation(
                    e.currentTarget.checked,
                    onlyAlphanumericString(e.currentTarget.value),
                    e.currentTarget.value.includes('external')
                  )
                }
              />
            )(isLoadingStations, stationsLoadingTemplate())}
          </LabeledCardCol>
          <div className={styles.selectPeriod}>
            <div>
              <label className={styles.label} htmlFor="initDate">
                Período
              </label>
              <Form>
                <DrawerRow>
                  <Form.InputText
                    name="initDate"
                    htmlType="date"
                    onChange={(e) => handleDebouncePeriod(e.currentTarget.name, e.currentTarget.value)}
                    max={period.finalDate}
                  />
                  <Form.InputText
                    name="finalDate"
                    htmlType="date"
                    onChange={(e) => handleDebouncePeriod(e.currentTarget.name, e.currentTarget.value)}
                    min={period.initDate}
                  />
                </DrawerRow>
              </Form>
            </div>
          </div>
        </div>
      </LabeledCard>

      <BoxedTable
        data={precipitations.items ?? []}
        table={table}
        title="Informações"
        disableNavigation
        hideQuantityLabel
        isLoading={isLoading}
        noDataMessage="Nenhuma informação para ser exibida."
      />

      <PrecipitationCreateModal
        createMeasurement={createMeasurement}
        handleModal={handleModal}
        isModalOpen={isModalOpen.create}
        stationsDroplist={stationsDroplist}
      />

      <PrecipitationUpdateModal
        updateMeasurement={updateMeasurement}
        handleModal={handleModal}
        isModalOpen={isModalOpen.update}
        stationsDroplist={stationsDroplist || []}
        selectedItem={selectedItem}
      />

      <PrecipitationDeleteModal
        deleteMeasurementItem={deleteMeasurementItem}
        handleModal={handleModal}
        isModalOpen={isModalOpen.delete}
        selectedItem={selectedItem}
      />
    </BoxedLayout>
  )
}
export { PrecipitationTemplate }
