import { useCallback, useEffect, useState } from 'react'

import {
  deleteMeasurement,
  getReadPrecipitations,
  patchUpdateMeasurement,
  postCreateMeasurement,
} from 'app/core/services/precipitations'
import { PrecipitationHook } from 'app/core/types/hooks'
import {
  CreateMeasurementRequestData,
  PrecipitationFilterProps,
  PrecipitationResponseProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const usePrecipitations = ({
  stationIds,
  externalStationIds,
  init_date,
  end_date,
  page,
}: PrecipitationFilterProps): PrecipitationHook => {
  const [precipitations, setPrecipitations] =
    useState<PrecipitationResponseProps>({
      items: [],
      total: 0,
      pages: 0,
      page: 0,
      size: 0,
    })
  const [isLoading, setIsLoading] = useState(false)

  const readPrecipitations = useCallback(async () => {
    if (stationIds || externalStationIds) {
      if (stationIds?.length === 0 && externalStationIds?.length === 0) {
        setPrecipitations({ items: [], total: 0, pages: 0, page: 0, size: 0 })
        addToast({
          message: Messages.PRECIPITATIONS_STATION_REQUIRED,
          type: 'warning',
        })

        return
      }

      setIsLoading(true)
      try {
        const precipitations = await getReadPrecipitations(
          stationIds || [],
          externalStationIds || [],
          init_date,
          end_date,
          page,
          DEFAULT_ITEMS_PER_PAGE_DETAILS
        )
        setPrecipitations(precipitations)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    }
  }, [stationIds, externalStationIds, init_date, end_date, page])

  const createMeasurement = useCallback(
    async (request: CreateMeasurementRequestData) => {
      try {
        const precipitation = await postCreateMeasurement(request)

        setPrecipitations(prevState => ({
          ...prevState,
          items: [precipitation, ...prevState.items],
        }))

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_CREATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  const updateMeasurement = useCallback(
    async (measurementId: number, request: UpdateMeasurementRequestData) => {
      try {
        const precipitation = await patchUpdateMeasurement(
          measurementId,
          request
        )

        setPrecipitations(prevState => ({
          ...prevState,
          items: [precipitation, ...prevState.items],
        }))

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  const deleteMeasurementItem = useCallback(
    async (measurementId: number) => {
      try {
        await deleteMeasurement(measurementId)

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  useEffect(() => {
    readPrecipitations()
  }, [readPrecipitations])

  return {
    precipitations,
    createMeasurement,
    updateMeasurement,
    deleteMeasurementItem,
    isLoading,
  }
}

export { usePrecipitations }
