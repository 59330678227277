/* eslint-disable @typescript-eslint/naming-convention */
import * as XLSX from 'xlsx'

import { getInseminators, getReadAllAnimals } from 'app/core/services'
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  HeatTypes,
  ImportedInseminationsProps,
  InseminationAnimalProps,
} from 'app/core/types/hormonal'
import { parseXlsxDate } from 'app/core/utils'

const xlsxToAnimals = async (
  content: string,
  breedingStationId?: number
): Promise<InseminationAnimalProps[]> => {
  const data = new Uint8Array(content as unknown as ArrayBuffer)
  const workbook = XLSX.read(data, {
    type: 'array',
    cellText: false,
    cellDates: true,
  })

  const inseminations = [] as Partial<InseminationAnimalProps>[]

  await Promise.all(
    workbook.SheetNames.map(async sheetName => {
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        dateNF: 'd"/"m"/"yyyy',
        raw: true,
      }) as ImportedInseminationsProps[]

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i]

        const animalInfo = row[0] || row[1] || row[2]
        const insemination_date = parseXlsxDate(row[3])
        const heat_type = String(row[4]).trim()
        const breeding_animal_stock_number = String(row[4]).trim()
        const semen_implanted_stock_number = String(row[5]).trim()
        const inseminator = row[6]
        const searchInseminatorById = typeof inseminator === 'number'

        const isInseminationImport =
          heat_type.includes(HeatTypes.hormonal_protocol) ||
          heat_type.includes(HeatTypes.natural_heat)

        const inseminationFilters = {
          stock_number_or_birth_number_or_electronic_eartag: String(animalInfo)
            .replace(/\n/g, '')
            .trim(),
          ...(breedingStationId &&
            !isInseminationImport && {
              breeding_station_id: breedingStationId,
            }),
        } as AnimalFilterProps

        const animalFilters = {
          like_stock_number_or_birth_number_or_electronic_eartag:
            isInseminationImport
              ? semen_implanted_stock_number
              : breeding_animal_stock_number,
        }

        let animalInsemination: Partial<InseminationAnimalProps>

        const inseminatedAnimal = (await getReadAllAnimals(inseminationFilters))
          .items[0]

        if (!inseminatedAnimal) {
          animalInsemination = {
            insemination_date,
            error_message: `Animal inseminado não encontrado: ${animalInfo}`,
          }

          inseminations.push(animalInsemination)

          continue
        }

        const semenOrBreedingAnimal = (await getReadAllAnimals(animalFilters))
          .items[0]

        if (!semenOrBreedingAnimal) {
          animalInsemination = {
            animal_id: inseminatedAnimal.id,
            electronic_eartag: inseminatedAnimal.electronic_eartag,
            stock_number: inseminatedAnimal.stock_number,
            insemination_date,
            error_message: `Sêmen ou animal reprodutor não encontrado.`,
          }

          inseminations.push(animalInsemination)

          continue
        }

        const systemInseminator = (
          await getInseminators({
            ...(searchInseminatorById && { id: inseminator }),
            ...(!searchInseminatorById && {
              name: String(inseminator).trim(),
            }),
          })
        ).items[0]

        animalInsemination = {
          animal_id: inseminatedAnimal.id,
          electronic_eartag: inseminatedAnimal.electronic_eartag,
          stock_number: inseminatedAnimal.stock_number,
          insemination_date,
          ...(isInseminationImport && {
            heat_type: heat_type as HeatTypes,
            semen_implanted: semenOrBreedingAnimal.id,
            semen_implanted_stock_number: semenOrBreedingAnimal.stock_number,
            inseminator_id: systemInseminator?.id,
            responsible_inseminator: systemInseminator?.name || '',
          }),
          ...(!isInseminationImport && {
            heat_type: HeatTypes.natural_heat,
            breeding_animal_id: semenOrBreedingAnimal.id,
            breeding_animal_stock_number: semenOrBreedingAnimal.stock_number,
          }),
        }

        inseminations.push(animalInsemination)
      }
    })
  )

  return inseminations as InseminationAnimalProps[]
}

export { xlsxToAnimals }
