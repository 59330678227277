import { postRevertShutdown } from "app/core/services"
import { addToast } from "app/core/utils"
import { Messages } from 'config/messages'

const useRevertShutdown = async (animalId: number): Promise<void> => {
    try {
        await postRevertShutdown(animalId)
        addToast({
            message: Messages.ANIMAL_REVERT_SHUTDOWN_SUCCESS,
            type: 'success'
          })
    } catch (e) {
        addToast({ message: (e as Error).message })
    }
}

export { useRevertShutdown }
