/* eslint-disable @typescript-eslint/naming-convention */
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useProtocol } from 'app/core/hooks'
import { IParamProps } from 'app/core/routes/routes'
import {
  ProtocolActionProps,
  ProtocolValuesProps,
} from 'app/core/types/hormonal'

import { protocolActionColumns } from '../tables'
import { ProtocolDetailsTemplate } from './template'

export type FormRef = {
  resetForm: () => void
}

const ProtocolDetails: React.FC = () => {
  const ref = useRef<FormRef>(null)

  const { protocolId } = useParams<IParamProps>()

  const { protocol, addProtocolActions, removeProtocolAction, isLoading } =
    useProtocol({
      protocolId,
    })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState<
    ProtocolActionProps | undefined
  >()

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleAddActions = async (data: unknown): Promise<void> => {
    const { actions } = data as ProtocolValuesProps

    await addProtocolActions(actions)

    ref?.current?.resetForm()

    handleToggleDrawer()
  }

  const handleActionId = (action: ProtocolActionProps): void => {
    setIsModalOpen(true)
    setSelectedAction(action)
  }

  const handleRemoveAction = (selectedActionId: string | number): void => {
    removeProtocolAction(selectedActionId)
    setIsModalOpen(false)
  }

  const table = useReactTable({
    data: protocol?.actions || [],
    columns: protocolActionColumns(
      handleActionId,
      protocol?.has_vinculated_iatf_schedule,
    ),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!protocol) {
    return null
  }

  const defaultActionsValues: ProtocolValuesProps = {
    actions: [{ day: 0, action: '', dose: 1, provider: '' }],
  }

  return (
    <ProtocolDetailsTemplate
      defaultActionsValues={defaultActionsValues}
      protocol={protocol}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      selectedAction={selectedAction}
      removeProtocolAction={handleRemoveAction}
      table={table}
      isDrawerOpen={isDrawerOpen}
      isLoading={isLoading}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleAddActions}
      parentRef={ref}
    />
  )
}

export { ProtocolDetails }
