import { DefaultPagination } from './system'

export enum ModalType {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export type ModalState = {
  create: boolean
  update: boolean
  delete: boolean
}

export type Period = {
  initDate: string,
  finalDate: string,
}

export type PrecipitationProps = {
  id: number
  date: string
  station_id: number
  temp_min: number
  temp_med: number
  temp_max: number
  rain: number
  editable: boolean
}

export type PrecipitationResponseProps = {
  items: PrecipitationProps[]
} & DefaultPagination

export type PrecipitationFilterProps = {
  stationIds?: number[]
  externalStationIds?: number[]
  init_date?: string
  end_date?: string
  page?: number
  size?: number
}

export type CreateMeasurementRequestData = {
  station_id: number
  date: string
  temp_min: number
  temp_med: number
  temp_max: number
  rain: number
  is_external_station: boolean
}

export type UpdateMeasurementRequestData = {
  station_id?: number
  date?: string
  temp_min?: number
  temp_med?: number
  temp_max?: number
  rain?: number
}
