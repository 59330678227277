import {
  CreateMeasurementRequestData,
  PrecipitationProps,
  PrecipitationResponseProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { http } from 'interfaces/http'

const baseUrl = '/precipitation/'

const getReadPrecipitations = async (
  station_id: number[],
  external_station_id: number[],
  init_date?: string,
  end_date?: string,
  page?: number,
  size?: number
): Promise<PrecipitationResponseProps> => {
  const response = await http.get(`${baseUrl}climate_per_hour/`, {
    paramsSerializer: { indexes: null },
    params: {
      station_id,
      external_station_id,
      init_date,
      end_date,
      page,
      size,
    },
  })
  return response.data
}

const postCreateMeasurement = async (
  request: CreateMeasurementRequestData
): Promise<PrecipitationProps> => {
  const response = await http.post(`${baseUrl}measurement/`, request)

  return response.data
}

const patchUpdateMeasurement = async (
  measurementId: number,
  request: UpdateMeasurementRequestData
): Promise<PrecipitationProps> => {
  const response = await http.patch(
    `${baseUrl}measurement/${measurementId}/`,
    request
  )

  return response.data
}

const deleteMeasurement = async (measurementId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}measurement/${measurementId}/`)

  return response.data
}

export {
  deleteMeasurement,
  getReadPrecipitations,
  patchUpdateMeasurement,
  postCreateMeasurement,
}
