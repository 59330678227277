import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { AnimalDrawer, BoxedTable } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import {
  AnimalDrawerType,
  AnimalSexLabel,
  AnimalSexPlural,
} from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingGroupProps,
  BreedingLoadingState,
  BreedingServiceAnimalProps,
} from 'app/core/types/breeding'
import { breedingGroupIdFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type BreedingGroupDetailsTemplateProps = {
  group?: BreedingGroupProps
  females?: BreedingGroupAnimalResponseData
  tableFemales: Table<BreedingServiceAnimalProps>
  femalePage: number
  setFemalePage: Dispatch<SetStateAction<number>>
  males?: BreedingGroupAnimalResponseData
  tableMales: Table<BreedingServiceAnimalProps>
  malePage: number
  setMalePage: Dispatch<SetStateAction<number>>
  selectedSex?: AnimalSexLabel
  isDrawerOpen: boolean
  handleToggleDrawer: (selectedSex?: AnimalSexLabel) => void
  onSubmit: IFormProps['onSubmit']
  isLoading: BreedingLoadingState
  resetFilters?: () => void
  filterIsActive?: BreedingLoadingState
  isLoadingExport: boolean
  onExportClick: () => void
}

const BreedingGroupDetailsTemplate: React.FC<BreedingGroupDetailsTemplateProps> =
  ({
    group,
    females,
    tableFemales,
    femalePage,
    setFemalePage,
    males,
    tableMales,
    malePage,
    setMalePage,
    selectedSex,
    isDrawerOpen,
    handleToggleDrawer,
    onSubmit,
    isLoading,
    resetFilters,
    filterIsActive,
    isLoadingExport,
    onExportClick,
  }) => {
    const [openDrawerType, setOpenDrawerType] = useState<AnimalDrawerType>()

    const defaultBoxedTableProps = {
      disableLastCellNavigation: true,
      mainButtonLabel: 'Incluir',
      mainButtonIcon: IconNames['add-circle'],
      noDataMessage:
        filterIsActive?.male || filterIsActive?.female
          ? Messages.ANIMALS_NOT_FOUND
          : Messages.ANIMAL_NOT_ADDED,
    }

    const handleResetFilters = (): void => {
      resetFilters?.call(this)
    }

    const isActive = group?.is_active

    const handleFilter = useCallback(
      (drawerType: AnimalDrawerType, sex: AnimalSexLabel): void => {
        setOpenDrawerType(drawerType)
        handleToggleDrawer(sex)
      },
      [handleToggleDrawer]
    )

    return (
      <>
        {group && (
          <ReproductionLayout
            backButton
            id={breedingGroupIdFormat(group.id)}
            title={group.name}
            initialDate={group.init_date}
            finalDate={group.final_date}
            breedingStationId={group.breeding_station_id}
            breedingStationName={group.breeding_station_name}
            headerButtonLabel="Exportar"
            headerButtonIcon={<Icon name={IconNames.download} />}
            headerButtonAction={onExportClick}
            headerButtonIsLoading={isLoadingExport}
          >
            {females && (
              <BoxedTable
                title={AnimalSexPlural.female}
                table={tableFemales}
                data={females.items || []}
                currentPage={femalePage}
                totalPages={females.pages}
                totalItems={females.total}
                setPage={setFemalePage}
                disablePaginationScrollTop
                mainButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.include, AnimalSexLabel.female)
                }
                secondaryButtonLabel={'Filtrar'}
                secondaryButtonIcon={IconNames.filter}
                secondaryButtonAction={(): void => {
                  handleFilter(AnimalDrawerType.filter, AnimalSexLabel.female)
                }}
                secondaryButtonClassName={
                  filterIsActive?.female
                    ? styles.filterButtonActive
                    : styles.filterButton
                }
                mainButtonProps={{
                  disabled: !isActive,
                  ...(!isActive
                    ? { tooltip: Messages.BREEDING_GROUP_INACTIVE_TOOLTIP }
                    : {}),
                }}
                isLoading={isLoading.female}
                isTableHeaderSticky
                {...defaultBoxedTableProps}
              />
            )}
            {males && (
              <BoxedTable
                title={AnimalSexPlural.male}
                table={tableMales}
                data={males.items || []}
                currentPage={malePage}
                totalPages={males.pages}
                totalItems={males.total}
                setPage={setMalePage}
                disablePaginationScrollTop
                mainButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.include, AnimalSexLabel.male)
                }
                mainButtonProps={{
                  disabled: !isActive,
                  ...(!isActive
                    ? { tooltip: Messages.BREEDING_GROUP_INACTIVE_TOOLTIP }
                    : {}),
                }}
                secondaryButtonLabel={'Filtrar'}
                secondaryButtonIcon={IconNames.filter}
                secondaryButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.filter, AnimalSexLabel.male)
                }
                secondaryButtonClassName={
                  filterIsActive?.male
                    ? styles.filterButtonActive
                    : styles.filterButton
                }
                isLoading={isLoading.male}
                isTableHeaderSticky
                {...defaultBoxedTableProps}
              />
            )}
          </ReproductionLayout>
        )}

        <AnimalDrawer
          includeTitle={`Incluir ${
            selectedSex && AnimalSexPlural[selectedSex]
          }`}
          filterTitle={`Filtrar ${selectedSex && AnimalSexPlural[selectedSex]}`}
          isOpen={isDrawerOpen}
          onToggle={handleToggleDrawer}
          onReset={handleResetFilters}
          onSubmit={onSubmit}
          drawerType={openDrawerType}
          disableIfEmpty={openDrawerType === AnimalDrawerType.include}
          disabledMessage='É necessário preencher algum parâmetro para aplicar a busca'
          {...(selectedSex === AnimalSexLabel.female && {
            hasIATFScheduleDropdown: true,
          })}
        />
      </>
    )
  }

export { BreedingGroupDetailsTemplate }
