import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import { useMeasuringStation } from 'app/core/hooks/measuring-station'
import { usePrecipitations } from 'app/core/hooks/precipitations'
import {
  ModalState,
  ModalType,
  Period,
  PrecipitationProps,
} from 'app/core/types/precipitation'

import { precipitationColumns } from './table'
import { PrecipitationTemplate } from './template'

const Precipitation: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<ModalState>({
    create: false,
    update: false,
    delete: false,
  })
  const [selectedItem, setSelectedItem] = useState<PrecipitationProps>()
  const [stationIds, setStationsIds] = useState<number[]>([])
  const [externalStationIds, setExternalStationIds] = useState<number[]>([])
  const [page, setPage] = useState<number>(1)

  const [period, setPeriod] = useState<Period>({
    initDate: '',
    finalDate: '',
  })

  const {
    measuringStations: stations,
    measuringStationsOptions,
    isLoading: isLoadingStations,
  } = useMeasuringStation()

  const {
    precipitations,
    createMeasurement,
    updateMeasurement,
    deleteMeasurementItem,
    isLoading,
  } = usePrecipitations({
    stationIds,
    externalStationIds,
    init_date: period.initDate,
    end_date: period.finalDate,
    page: page,
  })

  const handleModal = (type: ModalType): void => {
    setIsModalOpen(prevState => ({
      ...prevState,
      [type]: !prevState[type as unknown as keyof ModalState],
    }))
  }

  const handleStation = (
    isChecked: boolean,
    value: string,
    isExternalStation: boolean
  ): void => {
    if (isExternalStation) {
      return isChecked
        ? setExternalStationIds(prevState => [...prevState, Number(value)])
        : setExternalStationIds(prevState =>
          prevState.filter(selectedValue => selectedValue !== Number(value))
        )
    }

    return isChecked
      ? setStationsIds(prevState => [...prevState, Number(value)])
      : setStationsIds(prevState =>
        prevState.filter(selectedValue => selectedValue !== Number(value))
      )
  }

  const handlePeriod = (name: string, value: string): void => {
    setPeriod(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditClick = useCallback((item: PrecipitationProps): void => {
    setSelectedItem(item)
    handleModal(ModalType.update)
  }, [])

  const handleRemoveClick = useCallback((item: PrecipitationProps): void => {
    setSelectedItem(item)
    handleModal(ModalType.delete)
  }, [])

  const columns = useMemo<ColumnDef<PrecipitationProps>[]>(
    () =>
      precipitationColumns(stations || [], handleEditClick, handleRemoveClick),
    [stations, handleEditClick, handleRemoveClick]
  )

  const table = useReactTable({
    data: precipitations ? precipitations.items : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <PrecipitationTemplate
      handleStation={handleStation}
      handlePeriod={handlePeriod}
      period={period}
      handleModal={handleModal}
      isLoading={isLoading}
      isLoadingStations={isLoadingStations}
      isModalOpen={isModalOpen}
      table={table}
      precipitations={precipitations}
      stationsCheckboxList={measuringStationsOptions}
      stationsDroplist={measuringStationsOptions}
      createMeasurement={createMeasurement}
      updateMeasurement={updateMeasurement}
      deleteMeasurementItem={deleteMeasurementItem}
      selectedItem={selectedItem}
      setCurrentPage={setPage}
      currentPage={page}
    />
  )
}

export { Precipitation }
